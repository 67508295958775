package com.ektar.daas_sdk.core

import com.ektar.daas_sdk.model.DataPoint
import com.ektar.daas_sdk.utils.ApiConstants
import com.ektar.daas_sdk.utils.Constants
import com.ektar.daas_sdk.utils.Logger
import com.ektar.daas_sdk.utils.Utility
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLIFrameElement
import org.w3c.dom.HTMLScriptElement
import org.w3c.dom.MessageEvent
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventListener
import org.w3c.dom.get
import kotlin.js.Promise

private external fun encodeURIComponent(input: String): String

@OptIn(ExperimentalJsExport::class)
@JsExport
actual open class EktarBanner(dataPoint: DataPoint) : CommonEktarBanner(dataPoint), ApiListener {

    @JsName("ektar_daas_env")
    var ektarDaasEnv: String = ""

    @Suppress("unused")
    actual fun loadBanner() {
        ApiConstants.setEnvironment(ektarDaasEnv)
        setDeviceIdAndGetOffer()
    }

    actual fun setDeviceIdAndGetOffer() {
        val userAgent = window.navigator.userAgent
        dp.deviceType = getDeviceType()
        dp.deviceId = window.btoa(userAgent)
		dp.ipAddress = getIpAddress()
        dp.ekId = retrieveEkId()
		dp.version = Utility.getSdkVersion()
		dp.platform = Utility.getPlatform()
		val location = getLocation()
		location.then(onFulfilled = {
			if (it != null) {
				dp.latLng = it as String
			}
		}).catch {
		}.finally {
			getOffer()
		}
    }

    actual fun getDeviceType(): String {
        return if (isRequestFromMobileDevice()) {
            "WEB_MOBILE"
        } else {
            "WEB_DESKTOP"
        }
    }

	actual fun getIpAddress(): String {
		return ""
	}

    @Suppress("unused")
    actual fun setApiListener(apiListener: ApiListener) {
    }

    override fun onSuccess(s: String) {
        loadHtmlBanner(s)
    }

    override fun onFailure(message: String) {
        Logger.logError("Get merchant offers api failed", message)
    }

    private fun getOffer() {
        if (document.readyState.toString() == "loading") {
            try {
                document.addEventListener("DOMContentLoaded", EventListener {
                    registerLoadBanner()
                })
            } catch (e: Exception) {
                console.log("Ashok exception", e.toString())
            }
        } else {
            registerLoadBanner()
        }
    }

    private fun registerLoadBanner() {
        val ekIframeSingleBanner: HTMLDivElement?
		val ekSingleBannerDiv: HTMLDivElement?
		if (document.getElementById(Constants.IFRAME_BANNER_ID) != null) {
            ekIframeSingleBanner =
                document.getElementById(Constants.IFRAME_BANNER_ID) as HTMLDivElement
            ekIframeSingleBanner.hidden = false
            handleIframeBanner()
        }else if (document.getElementById(Constants.SIMPLE_BANNER_ID) != null) {
            ekSingleBannerDiv = document.getElementById(Constants.SIMPLE_BANNER_ID) as HTMLDivElement
            ekSingleBannerDiv.hidden = true
            getBanner(this)
        } else {
			Logger.logError("registerLoadBanner", ApiConstants.viewNotProvidedMessage)
		}
    }

    private fun loadHtmlBanner(bannerSnippet: String) {
        val ekSingleBannerDiv = document.getElementById(Constants.SIMPLE_BANNER_ID) as HTMLDivElement
        ekSingleBannerDiv.hidden = false
        val htmlObject = document.createElement("div")
        htmlObject.innerHTML = bannerSnippet
        val scriptValue = htmlObject.getElementsByTagName("script")[0]?.innerHTML
        if (scriptValue != null) {
            val script = document.createElement("script")
            script.innerHTML = scriptValue
            ekSingleBannerDiv.appendChild(script)
            ekSingleBannerDiv.innerHTML = bannerSnippet
        }
    }

	private fun isRequestFromMobileDevice(): Boolean {
		val userAgent = window.navigator.userAgent
		val regexpPattern =
			"Android|webOS|iPhone|iPod|BlackBerry|BB|Windows Phone|IEMobile|Opera Mini"
		val regex = Regex(regexpPattern)
		return regex.containsMatchIn(userAgent)
	}

    private fun handleIframeBanner() {
        if (document.getElementById(Constants.IFRAME_BANNER_ID) != null) {

			val keyForUpdateImpressionCount = Constants.UPDATE_IMPRESSION_COUNT
			val origin = ApiConstants.GET_OFFER_API_V2

			val interSectionObserverScript = document.createElement("script")
				as HTMLScriptElement
			interSectionObserverScript.innerHTML = """
				window.addEventListener('load', () => {
                    const iframe = document.getElementById("ek-iframe");
                    if (iframe) { // Check if the iframe exists
						if ('IntersectionObserver' in window) {
							// Create an IntersectionObserver
							const observer = new IntersectionObserver((entries) => {
								entries.forEach(entry => {
									if (entry.isIntersecting) {
										observer.unobserve(entry.target);
										iframe.contentWindow.postMessage("$keyForUpdateImpressionCount",
										"$origin");
									}
								});
							});

							// Observe the iframe
							observer.observe(iframe);
						} else {
							console.log('IntersectionObserver is not supported in this browser');
						}
					} else {
						console.error('Iframe with id "ek-iframe" not found');
					}
                });
			""".trimIndent()
			document.body?.appendChild(interSectionObserverScript)

			loadContentToIFrameContainer(dp.buildGetOfferRequestUrl(this))
		}
    }

	private fun loadContentToIFrameContainer(iframe: String) {
		// Loading iFrame content to iFrame container
		val singleBannerIframeDiv =
			document.getElementById(Constants.IFRAME_BANNER_ID) as HTMLDivElement
		singleBannerIframeDiv.innerHTML = iframe

		singleBannerIframeDiv.addEventListener("load", {
			val childIframe = singleBannerIframeDiv.querySelector("#ek-iframe") as HTMLIFrameElement
			childIframe.contentWindow?.postMessage(Constants.GET_IFRAME_HEIGHT,
				ApiConstants.GET_OFFER_API_V2)
			listenForMessage()
		}, true)
	}

	private fun listenForMessage() {
		window.addEventListener("message", { event: Event ->
			val messageEvent = event as MessageEvent
			if (ApiConstants.isDaasProdOrNonProdUrl(messageEvent.origin)) {
				val message = messageEvent.data

				try {
					val response = JSON.parse<dynamic>(message.toString())
					if (response != null) {
						if (response.title === "setIframeHeight") {
							val messageValue = response.message
							if (messageValue != null) {
								val singleBannerIframeDiv = document.getElementById(Constants.IFRAME_BANNER_ID)
								if (singleBannerIframeDiv != null) {
									val iFrame = singleBannerIframeDiv.getElementsByTagName("iframe")[0]
										as HTMLIFrameElement
									iFrame.style.height = (messageValue + 20).toString() + "px"
								}
							}
						} else if (response.title === "executeJavascript") {
							eval(response.message as String)
						}
					}
				} catch (_: Throwable) {
					if (message is Int) {
						val singleBannerIframeDiv = document.getElementById(Constants.IFRAME_BANNER_ID)
						if (singleBannerIframeDiv != null) {
							val iFrame = singleBannerIframeDiv.getElementsByTagName("iframe")[0]
								as HTMLIFrameElement
							iFrame.style.height = (message + 20).toString() + "px"
						}
					}
				}
			}
		})
	}

    actual fun encodeUrl(input: String): String {
        return encodeURIComponent(input)
    }

	@Suppress("unused")
	actual fun saveEkId(ekIdValue: String) {
		return localStorage.setItem(Constants.EKID, ekIdValue)
	}

	actual fun retrieveEkId(): String {
		val ekId = localStorage.getItem(Constants.EKID)
		if (ekId === null){
			return ""
		}
		return ekId
	}

	@Suppress("unused")
	actual fun save(key: String, value: String) {
		return localStorage.setItem(key, value)
	}

	@Suppress("unused")
	actual fun retrieve(key: String): String? {
		return localStorage.getItem(key)
	}

	fun getLocation(): Promise<*> = js(
		"""{
			function getGeolocation() {
				return new Promise(function(resolve, reject) {
					try {
						if (navigator.geolocation) {
							navigator.permissions.query({
								name: "geolocation"
							}).then(function(result) {
								if (result.state === "granted") {
									navigator.geolocation.getCurrentPosition(function(position) {
										resolve(position.coords.latitude + "," + position.coords.longitude);
									}, function(error) {
										switch (error.code) {
											case error.PERMISSION_DENIED:
												reject(new Error("User denied the request for Geolocation."));
												break;
											case error.POSITION_UNAVAILABLE:
												reject(new Error("Location information is unavailable."));
												break;
											case error.TIMEOUT:
												reject(new Error("The request to get user location timed out."));
												break;
											default:
												reject(new Error("An unknown error occurred."));
												break;
										}
									});
								} else {
									reject(new Error("Geolocation is not enabled for this website."));
								}
							});
						} else {
							reject(new Error("Geolocation is not supported by this browser."));
						}
					} catch (err) {
						reject(new Error(err.message));
					}
				});
			}
			return getGeolocation();
		}"""
	) as Promise<*>
}
